import TYPES from '@/types';

import Vue from 'vue';

// Application
import SearchInvestmentProductFundTypeByCustomerQuery
  from '@/modules/my-investment/allianz/investment-product-fund-type/application/queries/search-investment-product-fund-type-by-customer-query';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import {
  InvestmentProductFundTypeEntity,
} from '@/modules/flagship/catalogs/investment-product-fund-type/domain/entities/investment-product-fund-type-entity';
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class DefineInvestmentTermViewModel {
  @Inject(TYPES.SEARCH_INVESTMENT_PRODUCT_FUND_TYPE_BY_CUSTOMER_QUERY)
  private readonly search_investment_product_fund_type!:
    SearchInvestmentProductFundTypeByCustomerQuery;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.define-investment-term';

  readonly view: Vue;

  readonly minimum_term_to_show_other_strategies = 10;

  is_loading = false;

  is_valid_form = false;

  term_label = '';

  term_slider_min_value = 1;

  term_slider_max_value = 20;

  see_more_moderate = false;

  term_is_valid_for_access_other_strategies = false;

  show_stay_with_current_strategy = false;

  strategy_options: Array<InvestmentProductFundTypeEntity> = [];

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
    this.updateTerm(this.state.investor_goal.term);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get can_continue() {
    return (this.state.strategy.id && this.term_is_valid_for_access_other_strategies)
      || !this.term_is_valid_for_access_other_strategies;
  }

  get secondary_button() {
    return this.term_is_valid_for_access_other_strategies ? this
      .translate('continue') : this.translate('accept');
  }

  updateTerm = (selected_term: number) => {
    this.term_is_valid_for_access_other_strategies = selected_term >= this
      .minimum_term_to_show_other_strategies;
    if (selected_term === 1) {
      this.term_label = this.translate('year');
    } else {
      this.term_label = this.translate('years');
    }
  }

  back = () => {
    this.view.$emit('prevStep');
  }

  close = () => {
    this.view.$emit('close');
  }

  openDefineGoalsFlow = () => {
    this.view.$emit('openDefineGoalsFlow');
  }

  updateInformation = () => {
    if (!this.term_is_valid_for_access_other_strategies) {
      this.view.$emit('close');
    }

    if (this.term_is_valid_for_access_other_strategies) {
      const payload = {
        term: this.state.investor_goal.term,
        strategy: this.state.strategy,
      };
      this.view.$emit('nextStep', payload);
    }
  }

  getMinYear = (fund_code: string) => {
    switch (fund_code) {
      case 'SWSRFP':
        return 1;
      case 'SWSMOD':
        return 10;
      default:
        return 1;
    }
  }

  seeMore = (fund_code: string) => {
    if (fund_code === 'SWSMOD') {
      this.see_more_moderate = true;
    }
  }

  loadInvestmentProductFundTypes = async () => {
    try {
      const investment_product_fund_types = await this
        .search_investment_product_fund_type.execute();

      if (investment_product_fund_types.length < 2) {
        this.show_stay_with_current_strategy = true;
      }

      investment_product_fund_types.forEach((fund_type) => {
        if (fund_type.label !== 'SWSRFP') {
          this.strategy_options.push({
            ...fund_type,
            tooltip: this.translate(fund_type.label),
            min_year: this.getMinYear(fund_type.label),
          });
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  initialize = async () => {
    this.state.is_loading = true;
    await this.loadInvestmentProductFundTypes();
    this.state.is_loading = false;
  }
}
